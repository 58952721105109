export type ProfileQuestionsResponse = {
    questions: ProfileQuestion[];
};

export type ProfileQuestion = {
    id: string;
    code: string;
    title: string;
    answers: ProfileAnswer[];
    choiceType: ProfileQuestionChoiceType;
    updatedAt?: string;
    isDateType?: boolean;
};

export type ProfileAnswer = {
    id: string;
    text: string;
};

export enum ProfileQuestionChoiceType {
    single = 'single',
    multiple = 'multiple',
}
