import {
    ProfileAnswer,
    ProfileQuestion,
    ProfileQuestionChoiceType,
} from '@/store/models/dtos/ygdProfile.dto';
import { ContentItemsRequest, CreateConvoRequest } from '@/store/models/dtos';
import { IAnswer } from '@/store/models/interfaces/answer.interface.dto';
import { AnswerType, ContentItemType, ConvoTypes } from '@/store/enums';
import {
    DateRangeType,
    DateType,
    IContentItem,
    IConvo,
    ITag,
    IValidationRules,
} from '@/store/models/interfaces';
import { OperationType } from '@/store/enums/operationType.enum';
import { Answer } from '@/store/models/answer.dto';
import { ContentItem } from '@/store/models';
import { ContentClient } from '@/api/content';
import { TagClient } from '@/api/tag';
import { TagType } from '@/store/enums';

export default class PdlImportService {
    private tagClient: TagClient;

    constructor(tagClient: TagClient) {
        this.tagClient = tagClient;
    }

    public async import(question: ProfileQuestion, countryCode: string) {
        try {
            // Create the convo
            const convo = await this.saveConvo(question.title, countryCode);
            // build content items from ygd answers
            const contentItems = [await this.makeContentItem(question, convo.id)];
            // Populate the content items
            await this.saveContentItems(convo, contentItems);
        } catch (error) {
            console.error(error);
        }
    }

    public async makeContentItem(
        question: ProfileQuestion,
        convoId: number,
    ): Promise<IContentItem> {
        if (question.isDateType) {
            return this.makeDateTypeContent(question, convoId);
        }
        const item = new ContentItem();
        const isMultipleChoice = question.choiceType === ProfileQuestionChoiceType.multiple;
        if (isMultipleChoice) {
            item.multipleChoice = isMultipleChoice;
            item.multipleChoiceOptions = {
                layout: 'horizontal',
                submitButtonText: 'Submit',
                maxLimit: question.answers.length,
                minLimit: 0,
            };
        }
        item.operationType = OperationType.Create;
        item.convoId = convoId;
        item.externalId = question.id;
        item.botId = -1;
        item.isPdl = false; // this is only 'true' when PDL is inserted into a normal convo.
        item.title = question.title;
        item.sort = 0;
        item.type = ContentItemType.Buttons;
        item.tags = await this.makeTags(this.makeTagName(question.code));
        item.answers = this.makeAnswersList(question.answers, convoId);
        return item;
    }

    makeValidationRule(question: ProfileQuestion) {
        const defaultConfig = {
            dateType: DateType.Any,
            dateRangeType: undefined,
            dateRangeValue: undefined,
            submitButtonText: 'Select Date',
        };

        const map = <Record<string, IValidationRules>>{
            Age: {
                dateType: DateType.Past,
                dateRangeType: DateRangeType.Years,
                dateRangeValue: 16,
                submitButtonText: question.title,
            },
        };

        return map[question.code] || defaultConfig;
    }

    async makeDateTypeContent(question: ProfileQuestion, convoId: number): Promise<IContentItem> {
        const item = new ContentItem();
        item.operationType = OperationType.Create;
        item.convoId = convoId;
        item.externalId = question.id;
        item.botId = -1;
        item.isPdl = false; // this is only 'true' when PDL is inserted into a normal convo.
        item.title = question.title;
        item.sort = 0;
        item.type = ContentItemType.DatePicker;
        item.tags = await this.makeTags(this.makeTagName(question.code));
        item.validationRules = this.makeValidationRule(question);
        return item;
    }

    public async makeTags(tagName: string): Promise<ITag[]> {
        const tags: ITag[] = await this.tagClient.fetch({ type: TagType.pdl });
        const tag = tags.find((tag) => tag.name === tagName);
        if (tag) {
            return [tag];
        }
        const newTag = await this.tagClient.create(tagName, TagType.pdl);
        return [newTag];
    }

    public makeAnswersList(answers: ProfileAnswer[], convoId: number): IAnswer[] {
        const questionAnswers = answers.map((answer) => this.makeAnswer(answer, convoId));
        const defaultAnswers = [
            this.makeAnswer({ id: '', text: "I don't consent to share" } as ProfileAnswer, convoId),
        ];
        return [...questionAnswers, ...defaultAnswers];
    }

    public makeTagName(questionCode: string): string {
        return `ygd-profile-${questionCode.toLowerCase()}`;
    }

    public makeAnswer(answer: ProfileAnswer, convoId: number): IAnswer {
        const item = new Answer();
        item.convoId = convoId;
        item.text = answer.text;
        item.type = AnswerType.Reply;
        item.externalId = answer.id;
        return item;
    }

    private async saveConvo(name: string, countryCode: string): Promise<IConvo> {
        const request = new CreateConvoRequest();
        request.name = name;
        request.userId = 0;
        request.channelCode = '';
        request.type = ConvoTypes.PDLConvo;
        request.isPdl = true;
        request.ygdCountry = countryCode.toUpperCase();
        const convo = await ContentClient.createConvo(request);
        return convo;
    }

    private async saveContentItems(convo: IConvo, contentItems: IContentItem[]): Promise<void> {
        const request = new ContentItemsRequest();
        request.userId = 0;
        request.convoId = convo.id;
        request.channelId = convo.channelId;
        request.contentItems = contentItems;
        await ContentClient.saveContentItems(request);
    }
}
