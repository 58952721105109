var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdl-import"},[_c('PageHeading',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":"Import PDL"}}),_c('div',{staticClass:"pdl-import__actions"},[_c('Select',{staticClass:"pdl-import__region m-b-4 m-r-2",attrs:{"options":_vm.countryOptions,"label":"Panel Country","has-margin-bottom":false},model:{value:(_vm.panelCountry),callback:function ($$v) {_vm.panelCountry=$$v},expression:"panelCountry"}}),_c('Input',{attrs:{"id":"search","label":"Search PDL convo","placeholder":"Enter your query","type":"text","name":"search","has-margin-bottom":false},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('Datatable',{attrs:{"data":{ items: _vm.tableItems },"is-loading":_vm.isLoading,"columns":[
            {
                value: 'select',
                header: '',
                type: 'slot',
                width: '25px',
            },
            {
                value: 'title',
                header: 'PDL CONVO',
                type: 'text',
                width: '60%',
            },
            {
                value: 'updatedAt',
                header: 'UPDATED AT',
                type: 'date',
                width: '30%',
                format: _vm.config.dateFormat,
            } ]},scopedSlots:_vm._u([{key:"select",fn:function(ref){
        var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedQuestionIds),expression:"selectedQuestionIds"}],attrs:{"type":"checkbox"},domProps:{"value":item.id,"checked":Array.isArray(_vm.selectedQuestionIds)?_vm._i(_vm.selectedQuestionIds,item.id)>-1:(_vm.selectedQuestionIds)},on:{"change":function($event){var $$a=_vm.selectedQuestionIds,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedQuestionIds=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedQuestionIds=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedQuestionIds=$$c}}}})]}}])}),_c('Dialog',{attrs:{"show-confirm-button":"","confirm-button-text":"Discard changes","close-button-text":"Continue Editing","is-dialog-visible":_vm.showDialog},on:{"onClose":_vm.hideDialog,"onClickOutside":_vm.hideDialog,"closeOnEscapeEvent":_vm.hideDialog,"confirmButtonOnClick":_vm.navigateToList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Are you sure?")]},proxy:true},{key:"body",fn:function(){return [_vm._v("Continue editing to save progress.")]},proxy:true}])}),_c('Stepper',{attrs:{"show-back-button":"","show-next-button":"","next-button-text":"Import PDLs","nav-is-opened":_vm.navIsOpened,"is-loading":_vm.isImporting,"next-button-is-disabled":!_vm.selectedQuestionIds.length},on:{"backButtonOnClick":_vm.onBackButtonClick,"nextButtonOnClick":_vm.onNextButtonClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }