<template>
    <div class="pdl-import">
        <PageHeading :breadcrumbs="breadcrumbs" title="Import PDL" />

        <div class="pdl-import__actions">
            <Select
                v-model="panelCountry"
                :options="countryOptions"
                label="Panel Country"
                :has-margin-bottom="false"
                class="pdl-import__region m-b-4 m-r-2"
            />

            <Input
                id="search"
                v-model="query"
                label="Search PDL convo"
                placeholder="Enter your query"
                type="text"
                name="search"
                :has-margin-bottom="false"
            />
        </div>

        <Datatable
            :data="{ items: tableItems }"
            :is-loading="isLoading"
            :columns="[
                {
                    value: 'select',
                    header: '',
                    type: 'slot',
                    width: '25px',
                },
                {
                    value: 'title',
                    header: 'PDL CONVO',
                    type: 'text',
                    width: '60%',
                },
                {
                    value: 'updatedAt',
                    header: 'UPDATED AT',
                    type: 'date',
                    width: '30%',
                    format: config.dateFormat,
                },
            ]"
        >
            <template v-slot:select="{ item }">
                <input v-model="selectedQuestionIds" type="checkbox" :value="item.id" />
            </template>
        </Datatable>

        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="showDialog"
            @onClose="hideDialog"
            @onClickOutside="hideDialog"
            @closeOnEscapeEvent="hideDialog"
            @confirmButtonOnClick="navigateToList"
        >
            <template #header>Are you sure?</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>

        <Stepper
            show-back-button
            show-next-button
            next-button-text="Import PDLs"
            :nav-is-opened="navIsOpened"
            :is-loading="isImporting"
            :next-button-is-disabled="!selectedQuestionIds.length"
            @backButtonOnClick="onBackButtonClick"
            @nextButtonOnClick="onNextButtonClick"
        />
    </div>
</template>

<script>
import PdlImportService from '@/store/services/pdlImportService.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import { TagType } from '@/store/enums';
import PageHeading from '@/components/ui/PageHeading';
import Datatable from '@/components/Datatable';
import Select from '@/components/forms/Select';
import Stepper from '@/components/ui/Stepper';
import countryList from '@/json/countries.ts';
import { Routes } from '@/enums/routes.enum';
import Input from '@/components/forms/Input';
import Dialog from '@/components/ui/Dialog';
import { ContentClient } from '@/api/content';
import { TagClient } from '@/api/tag';
import config from '@/config';

export default {
    name: 'PDLImportYgd',
    components: {
        PageHeading,
        Datatable,
        Stepper,
        Select,
        Dialog,
        Input,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            config,
            isLoading: false,
            isImporting: false,
            showDialog: false,
            selectedQuestionIds: [],
            breadcrumbs: [
                new Breadcrumb('PDL convos', { name: Routes.PdlList }),
                new Breadcrumb('Import PDL'),
            ],
            questions: [],
            countryCodeList: ['gb'],
            panelCountry: 'gb',
            query: '',
        };
    },
    computed: {
        tableItems() {
            return this.questions.filter((question) => {
                return question.title.toLowerCase().includes(this.query.toLowerCase());
            });
        },
        selectedQuestions() {
            return this.questions.filter((q) => this.selectedQuestionIds.includes(q.id));
        },
        countryOptions() {
            return this.countryCodeList.map((iso2) => {
                const country = countryList.find((o) => o.iso2 === iso2.toUpperCase());
                const value = country ? country.name : iso2;
                return { id: iso2, value };
            });
        },
    },
    watch: {
        async panelCountry() {
            this.isLoading = true;
            this.questions = await this.getQuestions();
            this.isLoading = false;
        },
    },
    created() {
        this.importService = new PdlImportService(new TagClient(TagType.pdl));
    },
    async mounted() {
        this.isLoading = true;
        try {
            const [countries, questions] = await Promise.all([
                this.getCountries(),
                this.getQuestions(),
            ]);
            this.countryCodeList = countries;
            this.questions = questions;
        } catch (error) {
            this.$toaster.add(error);
        }
        this.isLoading = false;
    },
    methods: {
        async onNextButtonClick() {
            this.isImporting = true;
            const promises = this.selectedQuestions.map(async (question) => {
                const exists = await ContentClient.existsContentItem(question.id);
                if (!exists) {
                    await this.importService.import(question, this.panelCountry);
                } else {
                    this.$toaster.add(`"${question.title}" was already imported, skipping...`, {
                        type: 'info',
                    });
                }
            });
            await Promise.all(promises);
            this.isImporting = false;
            this.$router.push({ name: Routes.PdlList });
        },
        onBackButtonClick() {
            if (this.selectedQuestionIds.length) {
                this.showDialog = true;
            } else {
                this.navigateToList();
            }
        },
        hideDialog() {
            this.showDialog = false;
        },
        navigateToList() {
            this.$router.push({ name: Routes.PdlList });
        },
        async getCountries() {
            const countries = await ContentClient.getYouGovDirectPanelCountries();
            return countries;
        },
        async getQuestions() {
            const result = await ContentClient.getYouGovDirectProfileQuestions(this.panelCountry);
            return result.questions;
        },
    },
};
</script>

<style lang="scss" scoped>
.pdl-import {
    &__actions {
        display: flex;
        max-width: 600px;

        > div {
            flex: 1;
        }
    }
}
</style>
